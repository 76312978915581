/**********************
Global Styles
**********************/
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  background-color: #fff;
  color: #333;
  font-size: 17px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: roboto, sans-serif; }

button {
  background-color: transparent;
  border: 0; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 80px 0; }

.container {
  max-width: 1140px; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section-title {
  font-size: 52px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #5b5baf;
  font-family: "Berlin-Sans-FB-Demi-Bold"; }

.sub-heading {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #1a1c4d; }

strike {
  color: #5b5baf; }

.img-container {
  text-align: center; }

.sale-text {
  font-weight: 700;
  font-size: 26px;
  line-height: 1;
  margin: 16px 0;
  color: #5b5baf;
  text-transform: uppercase; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 10px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  box-shadow: none !important;
  outline: 0;
  font-weight: 700; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #1a1c4d;
  background-color: #1a1c4d;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: #5b5baf;
  border: 2px solid #5b5baf;
  color: #fff; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/** MEDIA QUERIES **/
@media only screen and (max-width: 1199px) {
  .container {
    max-width: 1060px; } }

@media only screen and (max-width: 991px) {
  section {
    padding: 50px 0; }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:active,
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:visited {
    font-size: 12px; }
  .container {
    max-width: 890px; } }

@media only screen and (max-width: 767px) {
  #main-nav {
    display: none !important; }
  .container {
    max-width: 100%; }
  .mobile-menu img {
    height: 34px; }
  .mobile-menu input + label {
    right: 15px; }
  .mobile-menu .navbar-brand {
    left: 15px;
    padding: 12px 15px 15px 0; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 30px; }
  p {
    font-size: 16px; } }

@media only screen and (max-width: 475px) {
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media only screen and (max-width: 450px) {
  section {
    padding: 40px 0; }
  .sub-heading {
    font-size: 18px;
    line-height: 24px; }
  .btn-prime,
  .btn-prime:link,
  .btn-prime:visited {
    font-size: 16px; }
  .sale-text {
    font-size: 22px; } }

@media only screen and (max-width: 375px) {
  .section-title {
    font-size: 22px !important; } }

/**********************
End of Global Styles
**********************/
/**********************
Navigation
**********************/
nav {
  padding: 0 !important; }

nav .nav-item {
  line-height: 36px; }

.buy-now {
  background-color: #1a1c4d !important;
  color: #fff !important; }

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:active,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:visited {
  color: #000;
  font-size: 13px;
  outline: 0;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 12px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase; }

.navbar-dark .navbar-nav .nav-link:hover {
  background-color: #1a1c4d;
  color: #fff; }

.navbar-brand:hover,
.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #1a1c4d; }

.navbar-dark .navbar-brand img {
  height: 45px; }

.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  margin-top: 0; }

.dropdown-item {
  background-color: #fff; }

.dropdown-menu.show {
  background-color: #fff;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f8f8;
  color: #212529; }

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #1a1c4d; }

.cta-btn {
  background-color: #1a1c4d; }

.cta-btn .nav-link {
  color: #fff !important; }

/* Mobile Menu */
header {
  background-color: #fff;
  position: relative;
  box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }

@media screen and (min-width: 768px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 767px) {
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #1a1c4d; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #000;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background: #fff;
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #000;
    font-weight: 700;
    display: block;
    padding: 15px;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: .05em; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 3000;
    padding: 17px 15px 15px 0; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 17px; } }

@media screen and (max-width: 450px) {
  .mobile-menu .navbar-brand img {
    height: 32px; } }

/**********************
Hero
**********************/
.hero {
  background-color: #f8f8f8;
  margin-top: 56px;
  padding: 60px 0; }
  .hero .section-title {
    font-size: 40px;
    margin-bottom: 0; }
    .hero .section-title span {
      display: block;
      font-weight: 400;
      font-size: 26px;
      opacity: 0.7;
      text-transform: initial; }
  .hero .sub-heading {
    color: #5b5baf; }
  .hero img {
    height: 500px; }

/* Media Queries */
@media only screen and (max-width: 991px) {
  .hero {
    text-align: center; }
    .hero .flex-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
    .hero img {
      height: 400px;
      margin-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .hero {
    margin-top: 60px; } }

@media only screen and (max-width: 600px) {
  .hero .section-title {
    font-size: 34px; }
    .hero .section-title span {
      font-size: 23px; }
  .hero img {
    height: 300px; } }

@media only screen and (max-width: 450px) {
  .hero {
    padding: 40px 0; }
    .hero .section-title {
      font-size: 26px; }
      .hero .section-title span {
        font-size: 20px;
        margin-bottom: 15px; } }

@media only screen and (max-width: 380px) {
  .hero img {
    height: 200px; } }

/************************************
 End of Hero
************************************/
/*************************
 About
*************************/
.about {
  padding: 50px 0;
  background-image: -webkit-gradient(linear, left top, right bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url("https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg");
  background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg");
  background-attachment: fixed;
  color: #fff; }
  .about .section-title {
    color: #fff; }
  .about img {
    max-width: 350px;
    border-radius: 50%;
    border: 5px solid #fff; }

/** MEDIA QUERIES **/
@media only screen and (max-width: 1199px) {
  .about img {
    max-width: 275px; } }

@media only screen and (max-width: 991px) {
  .about img {
    max-width: 200px;
    margin-bottom: 30px; } }

@media only screen and (max-width: 767px) {
  .about {
    text-align: center; }
    .about .section-title {
      margin-bottom: 10px; }
    .about .flex-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
    .about img {
      width: 300px; } }

@media only screen and (max-width: 450px) {
  .about {
    padding: 40px 0; }
    .about img {
      max-width: 150px; } }

/************************************
 End of About
************************************/
/*************************
Footer
*************************/
footer {
  text-align: center;
  background-color: #1a1c4d;
  padding: 30px 0; }
  footer p {
    margin-bottom: 5px;
    font-size: 14px;
    color: #fff; }
  footer .image img {
    height: 7px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

/**********************
End of Footer
**********************/
/**********************
Site Specific Styles
**********************/
/* Berlin Sans FB  */
@font-face {
  font-family: "Berlin-Sans-FB-Regular";
  src: url("../fonts/Berlin-Sans-FB-Regular.woff2") format("woff2"), url("../fonts/Berlin-Sans-FB-Regular.woff") format("woff"); }

@font-face {
  font-family: "Berlin-Sans-FB-Demi-Bold";
  src: url("../fonts/Berlin-Sans-FB-Demi-Bold.woff2") format("woff2"), url("../fonts/Berlin-Sans-FB-Demi-Bold.woff") format("woff"); }

/**********************
Announcement Bar
**********************/
.anouncement-bar {
  background-color: #5b5baf;
  padding: 10px;
  color: #fff; }
  .anouncement-bar h5 {
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 0; }

@media (max-width: 767px) {
  .anouncement-bar {
    margin-top: 62px;
    padding: 12px; } }

@media (max-width: 600px) {
  .anouncement-bar h5 {
    font-size: 16px; } }

/**********************
Navigation
**********************/
nav#main-nav {
  padding: 12px 0 !important; }

.navbar-dark .navbar-brand {
  position: absolute;
  top: 0; }

nav .nav-item {
  line-height: inherit; }

.cta-btn {
  border-radius: 3px;
  background-color: #1a1c4d;
  color: #fff; }
  .cta-btn .nav-link {
    color: #fff !important; }
  .cta-btn .nav-link:hover {
    background-color: #1a1c4d !important;
    border-radius: 3px; }

.navbar-dark .navbar-nav .nav-link:hover {
  background-color: transparent;
  color: #797979; }

.buy-now {
  background-color: #1a1c4d !important;
  color: #fff !important; }

.navbar-dark .navbar-brand img {
  height: 110px; }

small {
  display: inline-block;
  margin-bottom: 26px; }

.btn-prime .month {
  text-transform: initial; }

@media only screen and (max-width: 767px) {
  .mobile-menu img {
    height: 50px; }
  .mobile-menu .navbar-brand {
    padding: 5px 0 0 0 !important; } }

@media screen and (max-width: 600px) {
  .hero .btn-prime-dark {
    margin-bottom: 10px; } }

@media screen and (max-width: 450px) {
  .mobile-menu .navbar-brand img {
    height: 48px; } }

/**********************
Hero
**********************/
.hero {
  background: url("../img/hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  position: relative;
  padding: 80px 0;
  margin-top: 67px;
  height: 500px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff; }
  .hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4; }
  .hero .container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1; }
  .hero .section-title {
    font-size: 55px;
    margin-bottom: 5px;
    color: #fff; }
  .hero .sub-heading {
    color: #fff;
    font-size: 26px;
    line-height: 1.2; }
  .hero .sale-text {
    font-weight: 700;
    color: #5b5baf; }
  .hero p {
    font-size: 18px; }
  .hero img {
    height: auto; }
  .hero .cta-container {
    margin-top: 10px; }

@media only screen and (min-width: 1600px) {
  .hero {
    height: 900px; } }

@media only screen and (max-width: 1199px) {
  #hero .section-title {
    font-size: 34px; } }

@media only screen and (max-width: 767px) {
  .hero {
    margin-top: 60px; } }

@media only screen and (max-width: 600px) {
  .hero {
    background: #f8f8f8; }
    .hero p {
      font-size: 17px; }
  .hero::after {
    display: none; }
  .hero .container {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none; }
  .hero img {
    margin-bottom: 0; }
  .hero .sub-heading {
    color: #000;
    font-size: 18px; }
  .hero .sale-text {
    color: #000;
    line-height: 1.2;
    font-size: 18px; }
  .hero a.btn-prime.btn-prime-dark.monthly {
    margin-bottom: 10px; } }

@media only screen and (max-width: 450px) {
  #hero .flex-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; } }

/**********************
benefits
*************************/
.benefits {
  background-color: #fff;
  text-align: center; }
  .benefits .section-header {
    margin-bottom: 40px; }
  .benefits .item img {
    margin-bottom: 20px;
    max-width: 50%; }
  .benefits .title {
    font-weight: 700;
    color: #5b5baf; }
  .benefits .cta-container {
    margin-top: 50px; }
  .benefits .number {
    font-family: "Berlin-Sans-FB-Demi-Bold";
    font-size: 120px;
    color: #1a1c4d; }
  .benefits .bottom-text {
    margin-top: 40px; }

@media (max-width: 991px) {
  .benefits .section-header {
    margin-bottom: 10px; }
  .benefits .item {
    margin-bottom: 0; }
  .bottom-text {
    margin-top: 20px; } }

@media (max-width: 768px) {
  .benefits .number {
    line-height: 1; }
  .benefits .item {
    max-width: 500px;
    margin: 0 auto 50px; }
  .benefits .cta-container {
    margin-top: 40px; }
  .benefits .img-fluid {
    height: auto; } }

@media (max-width: 600px) {
  .benefits .item img {
    margin-bottom: 18px; }
  .benefits .item {
    margin: 0 auto 10px; }
  .sub-heading {
    text-align: center !important; }
  .benefits .bottom-text {
    margin-top: 30px; } }

/**********************
Included
**********************/
.included hr {
  height: 10px;
  background-color: #1a1c4d;
  max-width: 100px;
  margin-left: 0; }

.included .border-area {
  border-left: 1px solid #1a1c4d; }

.included .section-title {
  font-size: 80px;
  line-height: 1; }
  .included .section-title span {
    display: block; }

.included .text-area {
  padding-left: 20px; }
  .included .text-area .membership {
    max-width: 600px;
    margin-bottom: 20px; }
    .included .text-area .membership i {
      color: #1a1c4d;
      margin-top: 3px;
      margin-right: 10px; }
    .included .text-area .membership h5 {
      margin-bottom: 10px;
      font-weight: 700; }
    .included .text-area .membership p {
      margin-bottom: 5px;
      display: -webkit-box;
      display: flex; }

@media (max-width: 991px) {
  .included .section-title {
    font-size: 75px; } }

@media (max-width: 767px) {
  .included {
    text-align: center; }
    .included .section-title {
      font-size: 50px; }
      .included .section-title span {
        display: inline; }
  .included hr {
    margin: 20px auto 30px; }
  .included .text-area .membership p {
    -webkit-box-pack: center;
    justify-content: center; }
  .included .text-area .membership {
    margin: 0 auto 20px; } }

/**********************
awards
*************************/
.awards {
  background-color: #f8f8f8;
  text-align: center; }
  .awards .section-header {
    margin-bottom: 40px; }
  .awards .cta-container {
    margin-top: 30px; }

@media (max-width: 991px) {
  .awards .section-title {
    font-size: 30px; } }

@media (max-width: 767px) {
  .awards {
    text-align: center; }
    .awards .img-container {
      margin-bottom: 30px; } }

/**********************
Core Lessons
**********************/
.core-lessons {
  position: relative;
  text-align: center;
  padding-top: 40px; }
  .core-lessons .grid-row {
    margin: 40px 15px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px; }
    .core-lessons .grid-row .item .title {
      color: #fff;
      text-align: center;
      position: absolute;
      z-index: 1;
      margin-bottom: 0;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 80%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      padding: 10px 15px;
      font-weight: 700;
      font-size: 24px;
      background: #5b5baf;
      display: none; }
    .core-lessons .grid-row .inner {
      position: relative;
      width: 100%;
      max-width: 400px;
      margin: auto;
      overflow: hidden;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .core-lessons .grid-row .inner:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05); }
        .core-lessons .grid-row .inner:hover::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0.6; }
    .core-lessons .grid-row .inner .inner-overlay {
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s; }
    .core-lessons .grid-row .inner:hover .content-overlay {
      opacity: 1; }
    .core-lessons .grid-row .inner-image {
      width: 100%;
      padding: 20px; }
    .core-lessons .grid-row .inner-details {
      position: absolute;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;
      width: 100%;
      top: 50%;
      left: 50%;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
      z-index: 1; }
    .core-lessons .grid-row .inner:hover .inner-details {
      top: 50%;
      left: 50%;
      opacity: 1; }
    .core-lessons .grid-row .inner-details h3 {
      color: #fff;
      font-weight: 500;
      margin-bottom: 0.5em;
      font-size: 18px;
      font-weight: 700; }
    .core-lessons .grid-row .inner-details p {
      color: #fff;
      font-size: 0.8em; }
    .core-lessons .grid-row .fadeIn-bottom {
      top: 80%; }
    .core-lessons .grid-row .item {
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .core-lessons .grid-row .item:hover .title {
        opacity: 0; }
      .core-lessons .grid-row .item .img-container {
        position: relative;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .core-lessons .grid-row .item .img-container:hover {
          -webkit-transform: scale(1.08);
          transform: scale(1.08); }
          .core-lessons .grid-row .item .img-container:hover .btn-prime {
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
          .core-lessons .grid-row .item .img-container:hover .cta-container {
            display: block; }
        .core-lessons .grid-row .item .img-container::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0.3; }
      .core-lessons .grid-row .item .text-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 15px;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        z-index: 1; }
        .core-lessons .grid-row .item .text-inner .title {
          color: #fff;
          font-weight: 700; }
        .core-lessons .grid-row .item .text-inner .cta-container {
          display: none; }
      .core-lessons .grid-row .item img {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1); }
  .core-lessons .cta-container {
    margin-top: 40px; }

@media (max-width: 991px) {
  .core-lessons .grid-row {
    grid-template-columns: 1fr 1fr; }
    .core-lessons .grid-row .last-item {
      display: none; } }

@media (max-width: 600px) {
  .core-lessons .grid-row {
    grid-template-columns: 1fr; } }

/**********************
Bonding
*************************/
.bonding {
  background-color: #f8f8f8;
  text-align: center; }
  .bonding .img-left {
    background: url("../img/book-left.jpg") no-repeat left center;
    min-height: 600px;
    background-size: cover; }
  .bonding .img-right {
    background: url("../img/book-right.jpeg") no-repeat right center;
    min-height: 600px;
    background-size: cover; }
  .bonding .content-area {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 50px 15px; }
  .bonding .cta-container {
    margin-top: 20px; }

@media (max-width: 991px) {
  .bonding .content-area {
    -webkit-box-flex: 0;
    flex: 0 0 66%;
    max-width: 66%; }
  .bonding .img-left {
    display: none; } }

@media (max-width: 767px) {
  .bonding .content-area {
    background-color: #f8f8f8;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%; }
  .bonding .img-right {
    min-height: 400px; } }

/**********************
About
*************************/
.about {
  background-image: none;
  background-color: #f8f8f8;
  color: #000; }
  .about .section-title {
    color: #5b5baf; }
  .about img {
    border: none; }

@media (max-width: 767px) {
  .about .flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse; } }

/**********************
Purchase Options
**********************/
.purchase-options {
  text-align: center;
  /** Row with equal height columns  * */ }
  .purchase-options .section-header {
    margin-bottom: 60px; }
  .purchase-options .sale {
    position: absolute;
    top: -20px;
    left: -12px;
    display: inline-block;
    background: #5b5baf;
    color: #fff;
    font-weight: 700;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    vertical-align: middle;
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    z-index: 2; }
    .purchase-options .sale::after, .purchase-options .sale::before {
      content: "";
      position: absolute;
      background: inherit;
      height: inherit;
      width: inherit;
      top: 0;
      left: 0;
      z-index: -3;
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg); }

.package {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1;
  text-align: center;
  margin: 0 15px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  position: relative; }
  .package p {
    margin-bottom: 0; }
  .package .package__header {
    padding: 0; }
    .package .package__header .img-container {
      padding: 40px 20px 20px;
      position: relative; }
      .package .package__header .img-container .time {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: #fff;
        z-index: 1;
        font-family: "Berlin-Sans-FB-Demi-Bold";
        font-size: 40px; }
      .package .package__header .img-container img {
        height: auto;
        padding-top: 0; }
  .package__price {
    font-size: 38px;
    line-height: 1.5;
    color: #1a1c4d;
    font-weight: 700;
    margin: 0 0 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center; }
    .package__price strike {
      font-weight: 400;
      color: #9a9a9a; }
    .package__price .per-bundle {
      display: inline-block;
      font-size: 16px; }
  .package small {
    display: block;
    font-size: 14px;
    color: red;
    text-transform: initial;
    font-weight: 700; }
  .package__title {
    font-size: 20px;
    color: #1a1c4d;
    padding: 16px 0;
    margin-bottom: 0; }
    .package__title span {
      display: block;
      font-size: 16px;
      margin: 12px 0;
      color: #a27742;
      font-weight: 300;
      text-transform: uppercase; }
  .package__body p {
    font-weight: 400;
    margin-bottom: 20px;
    font-size: 15px;
    text-transform: capitalize; }
    .package__body p:not(:last-child) {
      margin-bottom: 8px; }
  .package .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    margin-bottom: 40px;
    border: 1px solid #ddd; }
  .package .bootstrap-select > .dropdown-toggle {
    padding: 10px 15px; }

.cta-container {
  widows: 100%; }

.package__btn {
  display: inline-block;
  margin-top: auto;
  padding: 14px;
  color: #fff;
  background-color: #1a1c4d;
  border: 0;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  letter-spacing: 0.8px;
  font-size: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .package__btn:hover {
    opacity: 0.8;
    color: #fff; }

.purchase-options .sale::after {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg); }

@media only screen and (max-width: 991px) {
  .purchase-options .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  .row-container {
    max-width: 600px;
    margin: 0 auto; }
  .purchase-options .sale {
    top: -20px;
    right: 0;
    left: initial;
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    font-size: 27px; }
  .purchase-options .package:not(:last-child) {
    margin-bottom: 70px; }
  .package .package__header .img-container img {
    height: 300px;
    padding-top: 30px; }
  .purchase-options .package__price {
    font-size: 41px;
    line-height: 41px;
    margin: 0 0 10px; }
  .purchase-options .package__title {
    font-size: 29px; }
  .purchase-options .package__title span {
    margin: 12px 0 30px; } }

@media only screen and (max-width: 600px) {
  .purchase-options .package__price {
    font-size: 30px;
    line-height: 36px; } }

@media only screen and (max-width: 450px) {
  .purchase-options .sale {
    font-size: 20px;
    height: 3.5rem;
    line-height: 3.5rem;
    width: 3.5rem; }
  .purchase-options .package__title {
    font-size: 22px; } }

@media only screen and (max-width: 350px) {
  .purchase-options .package__title {
    font-size: 18px; }
  .purchase-options .package__price {
    font-size: 24px;
    line-height: 30px; } }

/**********************
 Images
  **********************/
.images {
  overflow-x: hidden; }
  .images .slick-dots {
    position: inherit; }
  .images .slick-dotted.slick-slider {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-bottom: 60px; }

/**********************
Image Divider
*************************/
.img-divider {
  text-align: center;
  background-color: #5b5baf; }
  .img-divider img {
    margin-bottom: 40px; }
  .img-divider .section-title {
    color: #fff; }
  .img-divider .cta-container {
    margin-top: 5px; }
    .img-divider .cta-container .btn-prime-dark {
      border-color: #fed13d;
      background-color: #fed13d;
      color: #1a1c4d; }

/**********************
FAQ
*************************/
.faq {
  text-align: center;
  font-size: 16px; }
  .faq .section-header {
    margin-bottom: 40px; }

#questions {
  text-align: left; }
  #questions .card {
    margin-bottom: 30px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1); }
    #questions .card .card-header {
      background-color: #f8f8f8;
      padding: 0;
      border-bottom: 1px solid #e9e9e9; }
    #questions .card .card-body {
      border-bottom: 1px solid #ddd; }
  #questions .btn-link {
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: left;
    color: #1a1c4d;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 16px 50px 16px 16px;
    white-space: inherit;
    font-size: 18px;
    font-weight: 700; }
    #questions .btn-link i {
      position: absolute;
      top: 50%;
      right: 16px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); }

/**********************
CTA
*************************/
.cta {
  background-color: #000;
  text-align: center; }
  .cta .section-title {
    color: #fff;
    margin-bottom: 20px; }
  .cta small {
    margin-top: 16px;
    color: #fff; }
    .cta small .link-color {
      color: #fed13d; }

/**********************
Footer
*************************/
footer {
  background-color: #1a1c4d; }
  footer p {
    color: #fff; }
  footer .img-container {
    margin-bottom: 10px; }
    footer .img-container img {
      height: 50px; }
  footer .social {
    margin-bottom: 10px; }
    footer .social a {
      color: #5b5baf;
      font-size: 22px;
      margin: 0 8px; }

@media only screen and (min-width: 837px) {
  .small {
    display: none; } }

@media only screen and (max-width: 836px) {
  .big {
    display: none; } }

@media only screen and (max-width: 768px) {
  .hero {
    background-position: center; }
  .hero .section-title {
    font-size: 40px; }
  .testimonials {
    background-attachment: inherit; }
  .about .section-title {
    font-size: 38px; } }

@media only screen and (max-width: 767px) {
  .mobile-menu .navbar-brand {
    left: 15px;
    padding: 10px 15px 15px 0; }
  .hero {
    padding: 60px 0; }
  .testimonials {
    height: 500px; }
  .testimonials .text-area .quote {
    font-size: 22px; } }

@media only screen and (min-width: 601px) {
  .img-hero {
    display: none; } }

@media only screen and (max-width: 600px) {
  .btn-prime,
  .btn-prime:link,
  .btn-prime:visited,
  .purchase-options .package__btn {
    width: 100%; }
  .cta .section-title {
    font-size: 30px; }
  .hero {
    background-image: none;
    color: #000;
    display: inline-block;
    height: auto;
    padding-top: 0; }
  .hero img {
    height: auto; }
  .hero .section-title {
    color: #000; }
  .hero__text-area {
    padding-top: 50px; } }

@media only screen and (min-width: 451px) {
  .hero .mobile-copy {
    display: none; } }

@media only screen and (max-width: 450px) {
  .btn-prime,
  .btn-prime:link,
  .btn-prime:visited,
  .purchase-options .package__btn {
    font-size: 13px; }
  .section-title {
    font-size: 26px !important; }
  .sub-heading {
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 7px; }
  .hero .desktop-copy {
    display: none; }
  .hero {
    padding: 0 0 30px !important; }
    .hero .flex-reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
      .hero .flex-reverse .cta-container {
        margin-bottom: 20px; }
  .hero .section-title {
    text-align: center; }
  .hero .sub-heading {
    margin-bottom: 16px; }
  .hero .sale-text {
    margin-bottom: 20px; }
  .hero .sub-heading {
    text-align: center; }
  .sale-text {
    font-size: 13px;
    margin: 15px 0 20px; } }

@media only screen and (max-width: 320px) {
  .section-title {
    font-size: 20px !important; } }
